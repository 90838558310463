import React from "react"

const Youtube = ({ baseLayer, color1 }) => (
  <svg
    className={baseLayer}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
  >
    <path
      className={color1}
      d="M508.6,148.8c0-45-33.1-81.2-74-81.2C379.2,65,322.7,64,265,64h-18c-57.6,0-114.2,1-169.6,3.6C36.6,67.6,3.5,104,3.5,149
	C1,184.6-0.1,220.2,0,255.8c-0.1,35.6,1,71.2,3.4,106.9c0,45,33.1,81.5,73.9,81.5c58.2,2.7,117.9,3.9,178.6,3.8
	c60.8,0.2,120.3-1.1,178.6-3.8c40.9,0,74-36.5,74-81.5c2.4-35.7,3.5-71.3,3.4-107C512.1,220.1,511,184.5,508.6,148.8z M207,353.9
	V157.4l145,98.2L207,353.9z"
    />
  </svg>
)

export default Youtube
